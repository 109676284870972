import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, TermsCard, PolicyCard, PolicyTable } from "../common";

const Policy = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container mb-5">
        <div
          className="text-black fw-bold fs-4 my-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          PowWater – Privacy Policy
        </div>
        <div
          className="text-black fw-bold my-4"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Effective Date: Sept 04, 2024
        </div>
        <div className="shadow p-4">
          <TermsCard
            content={
              <>
                PowWater Corp. and PowWater Limited (“we,” “our,” or “us”) is
                committed to protecting your privacy. This Privacy Policy
                (“Policy”) describes how we collect, use, and disclose your
                information when you use our services (“Services”) or interact
                with us through our website,{" "}
                <Link to="/" className="text-primary text-decoration-underline">
                  https://www.powwater.com
                </Link>
                , our mobile application, email, social media sites, and other
                online services (“Sites”). This Policy explains:
              </>
            }
          />
          <PolicyCard
            list={[
              "the types of information we collect through our Sites and Services",
              "how we use and protect that information;",
              "the types of information we share with others and how we share it;",
              "the choices you have regarding our collection, use, and sharing practices;and",
              "details regarding our use of cookies and other tracking technologies.",
            ]}
          />
          <TermsCard content="We also include specific disclosures for residents of Kenya, as well as California and Nevada." />
          <TermsCard
            content="This Policy applies when you interact with us through our Sites and Services. It also applies anywhere it is linked. It
does not apply to non-PowWater websites or mobile applications that may link to our Sites or Services or be linked to
by our Sites or Services; please review the privacy policies on those websites and applications directly to understand
their privacy practices. It does not apply to non-PowWater websites or mobile applications that may link to our Sites or Services or be linked to by our Sites or Services; please review the privacy policies on those websites and applications directly to understand their privacy practices."
          />
          <div
            className="text-decoration-underline fs-3 fw-bold"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Information We Collect
          </div>
          <PolicyCard
            title={
              <span className="fs-4 fw-bold fst-italic">
                Information You Provide
              </span>
            }
            titleContent="We collect, process, and retain information you provide when using our Sites or Services. We collect the following
information from you:"
            list={[
              "When you place an order on our Sites or sign up for our Services through our mobile marketplace, we collect your name, email address, billing address, shipping address, payment information such as M-Pesa, PayPal or credit card information, telephone number, and information about your order such as amount of water ordered and the water vendor associated with your order. When you create an account, we collect your username and password;",
              <>
                <span className="fw-bold">Water Vendors.</span> When you sign up
                to be a water vendor on the PowWater platform, we collect your
                business name, branch name, KEBS certificates, KRA PIN number,
                phone number, contact name, payment information, marketing
                materials (such as photographs and business descriptions to be
                shown to PowWater users), inventory, and pricing information.
                When you create an account, we collect your username and
                password;
              </>,
              <>
                <span className="fw-bold">Delivery Riders.</span> When you sign
                up to be a delivery rider, we collect your name, phone number,
                payment information such as M-Pesa, and licensing or
                registration. When you create an account, we collect your
                username and password;
              </>,
              "Your email address when you subscribe to our email",
              "Your contact information and a record of any correspondence when you contact us, including your messages when you communicate with us via the PowWater mobile application. If you contact us via phone, we may record the call. We will notify you if a call is being recorded at the beginning of the call;",
              "Your postings when you communicate with us on social; and",
              "Information you provide when you complete a survey administered by us or a company acting on our behalf.",
            ]}
          />
          <PolicyCard
            title={
              <span className="fs-4 fw-bold fst-italic">
                Information We Collect Automatically
              </span>
            }
            titleContent="When you interact with our Sites and Services, certain information about your use is automatically collected. This
information is collected through cookies, web beacons, and other tracking technologies, as well as through your web
browser or device. We collect:"
            list={[
              "Details of your visits to our site and information generated in the course of the use of our site (including the timing, frequency and pattern of service use) including traffic data, weblogs and other communication data, the resources that you access, and how you reached our site.",
              "Details regarding the device you use to access our Sites and Services, including, but not limited to, your IP address, device identifier, operating system, and browser.",
              "Your precise geolocation, when you consent to our collection and use of this information through our mobile application.",
              "Information about how you interact with our ads and newsletters, including whether you open or click links in any correspondence.",
              "Information that you make available to us on a social media platform (such as by clicking on a social media icon linked from our Sites or Services), including your account ID or username and other information included in your posts.",
            ]}
          />
          <TermsCard content="We use cookies and similar tracking technologies for the following purposes:" />
          <PolicyCard
            list={[
              <>
                <span className="text-decoration-underline">Advertising: </span>
                Allowing us to provide you with targeted advertisements or
                marketing communications that we believe may be of interest to
                you.
              </>,
              <>
                <span className="text-decoration-underline">Analytics: </span>
                Allowing us to understand how our Sites and Services are being
                used, track site performance and make improvements.
              </>,
              <>
                <span className="text-decoration-underline">
                  Site Operations:{" "}
                </span>
                Enabling features that are necessary for providing you our Sites
                and Services, such as identifying you as being signed in,
                tracking content views, and remembering your preferences.
              </>,
              <>
                <span className="text-decoration-underline">Social Media:</span>{" "}
                Enabling the sharing of content from our Sites and Services
                through social networking and other sites.
              </>,
            ]}
          />
          <TermsCard
            content="Much of this information is collected through cookies, web beacons, and other tracking technologies, as well as through
your web browser or device. PowWater relies on partners to provide many features of our Sites and Services using
data about your use. Below is a list of these partners with links to more information about their use of your data and
how to exercise your options regarding tracking:"
          />
          <PolicyTable />
          <TermsCard
            content={
              <>
                Specifically, Google Analytics, through the use of cookies or
                other tracking technologies, helps us analyze how users interact
                with and use our website, compiles reports on website activity,
                and provides other services related to our website activity and
                usage. The technologies used by Google may collect information
                such as your location, time of visit, whether you are a return
                visitor, and any referring website. You can learn more about
                Google’s partner services and to learn how to opt out of
                tracking of analytics by Google on{" "}
                <a
                  className="text-primary text-decoration-underline"
                  href="https://policies.google.com/technologies/partner-sites?hl=en"
                >
                  Google’s Privacy and Terms
                </a>{" "}
                website.
              </>
            }
          />
          <TermsCard
            content="Most web browsers automatically accept cookies but, if you prefer, you can modify your browser setting to disable or
reject cookies. If you delete your cookies or if you set your browser to decline cookies, some features of the Services
may not be available, work, or work as designed. You may also be able to opt out of or block tracking by interacting
directly with the other companies who conduct tracking through our Services."
          />
          <TermsCard
            content={
              <>
                You can learn more about ad serving companies and the options
                available to limit their collection and use of your information
                by visiting the websites for the{" "}
                <a
                  href="https://optout.networkadvertising.org/?c=1"
                  className="text-primary text-decoration-underline"
                >
                  Network Advertising Initiative
                </a>{" "}
                and the{" "}
                <a
                  href="https://optout.aboutads.info/?c=2&lang=EN"
                  className="text-primary text-decoration-underline"
                >
                  Digital Advertising Alliance.
                </a>{" "}
                Similarly, you can learn about your options to opt out of mobile
                app tracking by certain advertising networks through your device
                settings and by resetting the advertiser ID on your Apple or
                Android device.
              </>
            }
          />
          <TermsCard content="Please note that opting out of advertising networks does not mean that you will not receive advertising while using our Sites and Services or on other websites, nor will it prevent the receipt of internet-based advertising from other companies that do not participate in these programs. It will, however, exclude you from interest-based advertising conducted through participating networks, as provided by their policies and choice mechanisms. If you delete your cookies, you may also delete your opt-out preferences. Information collected about you from a particular browser or device may be linked to information collected from another computer or device that we believe relates to you. Your browser or device may include “Do Not Track” functionality. At this time, we do not alter our sites’ data collection and use practices in response to Do Not Track signals." />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                How We Use Your Information
              </span>
            }
            titleContent="We use the personal information we collect through our Sites for the purposes indicated below to achieve the legitimate interests of the business. Please note that we do not use your personal information for any commercial purposes for which we have not sought your express consent. By accepting our terms of service, you voluntarily and unequivocally consent to the usage of your personal information for the purposes which include but is not limited to:"
            list={[
              "To facilitate the creation of accounts on our Sites and the subsequent logging into the accounts created. Where you opt to link your already existing third-party account (such as Google account or Apple account), we will collect such information and are not responsible for the accuracy or otherwise of information from third-party accounts.",
              "To send you marketing and promotional communications. We and/or our third-party partners who comply with the provisions of prevailing legislation relating to data privacy and confidentiality, may use the information you provide to us for marketing purposes subject to your marketing preferences. Please note that this is voluntary and you may opt out at any time.",
              "To send administrative information to you. We may use your information to send you information about products, Services and/or new feature information and/or information about the changes to aspects of our business which include but are not limited to our policies, terms and/or conditions of service.",
              "To post testimonials. We may choose to post testimonials on our Sites. By agreeing to the collection of your personal information, you agree to us posting your testimonials. If you wish to update, correct and/or delete your testimonial, please email us at contact@powwater.com or call us at +254111718463",
              "To deliver targeted advertising to you. We may use your personal information to develop and/or display content and advertising (and work with third-parties who do so and adhere to the provisions of prevailing legislation relating to data privacy and confidentiality) tailored to your interests and/or location and to measure the effectiveness of such targeted advertisements.",
              "Administer competitions and award prizes. We may use your personal information to administer prize draws and competitions that we set up during and in the course of running our business.",
              "To request feedback on our Services. We may use your personal information to request feedback and/or to contact you about your use of our Sites",
              "To enforce the terms of our agreements with third-parties, water vendors, delivery riders, users as well as our terms and/or conditions and other general contractual arrangements as well as the various memoranda of understanding that we may enter into from time to time",
              "To respond to any regulatory enquiries and any other purposes as may be determined by a judicial and/or quasi-judicial body",
              "To make any insurance related enquiries. We may have to liaise with our insurers, as is required by our insurance policies, and process personal information that will be crucial in the ascertainment, resolution and settlement of any such claims",
              "To provide you with the Sites and Services, including to communicate with you and to process your requests, transactions, and purchases, to provide you with customer support, and to provide you with information relating to our services, in accordance with the preferences you have shared with us",
              "To provide you with the best service and improve and grow our business, including to understand how our Sites and Services are being used, track site performance, and make improvements, improving and optimizing our Sites and Services, understanding our customer base, and understanding the effectiveness of our communications",
              "To provide you with the best service and improve and grow our business, including to understand how our Sites and Services are being used, track site performance, and make improvements, improving and optimizing our Sites and Services, understanding our customer base, and understanding the effectiveness of our communications●	For other purposes that are incidental to providing the Services as may be determined from time. We may use your personal information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, Services, marketing and your personal experience as you engage with us",
              "For other purposes that are incidental to providing the Services as may be determined from time. We may use your personal information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, Services, marketing and your personal experience as you engage with us",
              "To comply with legal process, such as warrants, subpoenas, court order, and lawful regulatory or law enforcement requests and to comply with legal requirements regarding the provision of the Services",
            ]}
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                How We Share Your Information
              </span>
            }
            list={[
              "There are various circumstances that may require us to share your personal data with third parties based in countries where we are registered as data processors. By accepting our terms of service, you voluntarily and unequivocally consent to the sharing of your personal data as we may deem appropriate",
              "Where we deem it appropriate to share your data with a third party, we will ensure that we obtain a guarantee from the third-party that such data will not be used for purposes beyond the legally acceptable basis for which it was provided or requested or collected",
              "We will only share your personal data with third parties who will agree to adhere by our Privacy Policy and prevailing legislation relating to data privacy and confidentiality. However, please note that while we endeavour to only associate with third parties with values and policies similar to our privacy policy, we can in no way guarantee the privacy of personal data that you provide to these third parties after you leave our Sites. When in doubt, please consult the privacy policy of the third parties before you provide personal and/or confidential information to these websites",
            ]}
          />
          <TermsCard
            content={
              <span className="fw-bold text-decoration-underline">
                We may share Your personal data with :
              </span>
            }
          />
          <PolicyCard
            list={[
              <>
                <span className="fw-bold">
                  Water Vendors and Delivery Riders:{" "}
                </span>
                Delivery Riders, and/or other contractors or agents who perform
                services for us or on our behalf and require access to such
                information to execute their obligations.
              </>,
              <>
                <span className="fw-bold">Service Providers: </span>
                We engage the following types of vendors to perform functions on
                our behalf: billing and collection; auditing and accounting;
                analytics services; marketing services providers; professional
                services consultants; security vendors; text messaging and email
                service providers; website hosting services; and IT vendors,
                including mobile application developers. These vendors are
                obligated by contract to use information that we share only for
                the purpose of providing these services.
              </>,
              <>
                <span className="fw-bold">Advertising Partners: </span> We
                partner with companies that assist us in advertising our
                Services. We also partner with other companies who use cookies
                to display interest-based advertising to you on our Sites and
                when procuring our Services.
              </>,
              <>
                <span className="fw-bold">Business Partners: </span> We may
                share your information with our business partners to offer you
                certain products, services or promotions.
              </>,
              <>
                <span className="fw-bold">Social Media Platforms: </span> Where
                you choose to interact with us through social media, your
                interaction with these programs typically allows the social
                media company to collect some information about you through
                cookies they place on your device and other tracking mechanisms.
                In some cases, the social media company may recognize you
                through its digital cookies even when you do not interact with
                their application. Please visit the social media companies’
                respective privacy policies to better understand their data
                collection practices and controls they make available to you.
              </>,
              <>
                <span className="fw-bold">
                  Change of Ownership or Corporate Organization:{" "}
                </span>{" "}
                We may transfer to another entity or its affiliates or service
                providers some or all information about you in connection with,
                or during negotiations of, any merger, acquisition, sale of
                assets or any line ofbusiness,change in ownership control, or
                financing transaction. We cannot promise that an acquiring party
                or the merged entity will have the same privacy practices or
                treat your information the same as described in this Policy.
              </>,
              <>
                <span className="fw-bold">Associate Entities: </span> We may
                share your information with associated entities, in which case
                we will require those associated entities honour this Privacy
                Policy. Associated entities include our parent company and any
                subsidiaries, joint venture partners or other companies that we
                control or that are under common control with us.
              </>,
              <>
                <span className="fw-bold">
                  Third Party Offerors and Advertisers:{" "}
                </span>{" "}
                The Sites may contain advertisements from third parties who are
                not affiliated with us and which may link to other websites,
                online services or mobile applications. We cannot guarantee the
                safety and privacy of data you provide to any third parties. Any
                data collected by third parties, who are not affiliated with us,
                is not covered by this Privacy Policy. We are not responsible
                for the content or privacy and security practices and policies
                of any third parties, including other websites, services or
                applications that may be linked to or from our Sites or mobile
                applications. You should review the policies of such third
                parties and contact them directly to respond to your questions.
              </>,
              <>
                <span className="fw-bold">
                  Law Enforcement, Legal Process, and Emergency Situations:{" "}
                </span>{" "}
                We also use or disclose your personal information if required to
                do so by law or on the good-faith belief that such action is
                necessary to (a) conform to applicable law or comply with legal
                process served on us or our sites; (b) protect and defend our
                rights or property, the Sites or our users which are not
                overridden by your interests, rights and freedoms as the data
                subject; or (c) act to protect the personal safety of us, users
                of our Sites, or the public.
              </>,
            ]}
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                How We Secure Your Information
              </span>
            }
            titleContent="We use a combination of appropriate physical, technical, organizational and administrative safeguards to protect the information we collect through our Sites and Services. While we use these precautions to safeguard your information, we cannot guarantee the security of the networks, systems, servers, devices, and databases we operate or that are operated on our behalf and therefore, you should only access our services within a secure environment and/or through trusted third-parties."
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                How long we use your information
              </span>
            }
            titleContent="We will generally only retain your personal information, per our data retention schedule, for a reasonably necessary period to fulfil the purposes we collected it for, including but not limited to the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We shall erase, delete, anonymize or pseudonymize your personal information upon the lapse of the purpose for processing your information. We may retain your personal information for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you. We may continue to store anonymized information, such as website visits, without identifiers, in order to improve our Services."
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Children’s Information
              </span>
            }
            titleContent="Our Sites and Services are intended for users who are 18 years of age and above. PowWater does not knowingly process personal information relating to a child. If we discover that we have inadvertently collected personal information relating to a child, we will deactivate such accounts and take all reasonable measures to delete that information within 24-hours of making that discovery."
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Controls for DO-NOT-TRACK(DNT) Features
              </span>
            }
            titleContent="Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy."
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Users Outside of the United States
              </span>
            }
            titleContent="If you use our Sites or Services outside of the United States, you understand that we collect, process, and store your information in the United States and in Kenya. The laws in the United States and Kenya regarding personal information may be different from the laws of your jurisdiction. Any such transfers will comply with safeguards as required by relevant law and we will ensure that a similar degree of protection is afforded to your personal information."
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Information for Individuals Located in Kenya
              </span>
            }
            titleContent="We process personal data, as defined in the Data Protection Act, for the following lawful reasons: (1) with your consent; (2) as necessary to perform our agreement with you to provide Services; (3) as necessary for our legitimate interests in providing the Services where those interests do not override your fundamental rights and freedoms related to data privacy; or (4) as necessary to comply with legal obligations. Information we collect may be processed including all sets of operations as defined in the Data Protection Act and transferred to the United States and Kenya. 

We process personal data of data subjects located in Kenya in accordance with the principles and obligations of personal data protection outlined in the Data Protection Act. If you are a resident of Kenya, we acknowledge and uphold the following rights: your right to be informed of the use we put your personal data to; your right to access your personal data; your right to object to processing of your personal data and your right to rectification and erasure. 

We abide by the principles in the Data Protection Act which include, lawfulness, fairness, transparency, purpose limitation, data minimization, accuracy, storage limitation, integrity and confidentiality.

We may require you to provide us with information so that we can verify your identity prior to giving you access to any records containing information about you. In accordance with your rights, you may request for:
"
            list={[
              "Access to your personal data",
              "Correction or deletion of your personal data that is inaccurate, out-dated, incomplete or misleading",
              "Erasure or destruction of your personal data when such data is no longer necessary for the purpose for which it was collected, when you withdraw consent and no other legal basis for processing exists, or when you believe that your fundamental rights to data privacy and protection outweigh our legitimate interest in continuing the processing",
              "Restriction on processing of your personal data if you contest the accuracy of your personal data, or if the processing is unlawful and you are opposed to erasure and you request for restriction instead",
              "Your personal data not to be processed for a specified purpose or in a specified manner. Upon your request to object the processing of your personal data, we shall restrict the processing pending our demonstration of our legitimate interests in processing your personal data which override your interests or provision of a verification that the processing of your personal data is necessary for the establishment, exercise or defence of a legal claim",
              "Transfer of your personal data to you, to a third party or to another data controller or processor. We will provide to you, to a third party of your choice or to a data controller or processor, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which we had collected with your consent and used for an agreed purpose or where we used the information to perform a contract with you",
              "With regard to marketing, your personal data not to be processed for direct marketing purposes which includes profiling to the extent that it is related to such direct marketing. You may also request for a restriction on the use or disclosure of your personal data to a third party for the purpose of facilitating direct marketing by a third party",
            ]}
          />
          <TermsCard content="To submit a request to exercise your rights, please contact us at contact@powwater.com or call us at +254111718463. You will not be charged for making a request for access to your personal data, rectification and erasure. We shall promptly notify you, in writing, where we decline any request and we shall provide reasons for such refusal. In addition, we may charge a reasonable fee if your request is manifestly unfounded, repetitive or excessive" />
          <TermsCard content="Where we transfer, with your consent, your personal data outside Kenya, we ensure that your personal data will be adequately protected and secured by the recipient and that appropriate safeguards are in place to achieve adequate data protection objectives" />
          <TermsCard content="If you are aggrieved by any decision we make in our capacity as a data controller, you may lodge a complaint with the Office of the Data Protection Commissioner in accordance with the Data Protection Act and the Data Protection (Complaints Handling Procedure and Enforcement) Regulations" />

          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Your California Privacy Rights
              </span>
            }
            titleContent="Residents of the State of California have the right to request information regarding third parties to whom we have disclosed certain categories of information during the preceding year for those third parties’ direct marketing purposes. We do not share your personal information with third parties for those third parties’ direct marketing purposes"
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Special Information for Nevada Residents
              </span>
            }
            titleContent="Residents of the State of Nevada have the right to opt out of the sale of certain pieces of their information to other companies who will sell or license their information to others. We do not sell your information at this time"
          />
          <PolicyCard
            title={
              <span
                className="text-decoration-underline fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Changes to this Policy
              </span>
            }
            titleContent="We may make changes to this Policy from time to time. The “Effective Date” at the top of this page indicates when this Policy was last revised"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
